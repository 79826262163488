import React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const CeramicCoating = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Ceramic Coating Services in Los Angeles</Heading>
        <Text>
          At LA Mobile Car Detailing, we offer cutting-edge ceramic coating
          services, a perfect solution for preserving your vehicle's paintwork
          while enhancing its overall look. In the vibrant city of Los Angeles,
          where luxury vehicles reign supreme, our ceramic coating provides a
          durable layer of protection against various elements, ensuring your
          car maintains its pristine appearance for years to come.
        </Text>
        <Text>
          This advanced treatment not only gives your car a glossy finish but
          also extends the life of its paint by protecting against scratches,
          chemical stains, and UV damage. Our skilled technicians meticulously
          apply the ceramic coating, ensuring every inch of your vehicle is
          covered with precision and care. With our expertise and attention to
          detail, you can trust us to deliver long-lasting results that exceed
          your expectations, leaving your car looking stunning and shielded from
          the rigors of daily driving.
        </Text>

        <UnorderedList spacing={3}>
          <ListItem>
            <b>Advanced Paint Protection:</b> Our ceramic coating offers
            superior protection, keeping your vehicle looking newer for longer.
            It forms a durable shield against environmental contaminants, road
            debris, bird droppings, and harsh weather conditions, preserving the
            integrity of your car's paintwork and reducing the risk of scratches
            and swirl marks.
          </ListItem>
          <ListItem>
            <b>Enhanced Gloss and Shine:</b> The ceramic coating amplifies your
            car’s shine, giving it a luxurious, wet look that stands out on the
            road. By enhancing the depth and clarity of your vehicle's paint, it
            creates a stunning visual impact, making your car appear as if it
            just rolled off the showroom floor.
          </ListItem>
          <ListItem>
            <b>Long-Lasting Durability:</b> Ceramic coatings provide a hard,
            protective surface that lasts for years, ensuring your vehicle
            maintains its showroom finish well into the future. With its
            exceptional resistance to fading, oxidation, and chemical damage,
            the ceramic coating keeps your car looking pristine and protected
            against the effects of aging and exposure.
          </ListItem>
          <ListItem>
            <b>Ease of Cleaning:</b> Dirt and grime wipe off easily, keeping
            your vehicle cleaner between washes. The hydrophobic properties of
            the ceramic coating repel water and contaminants, making maintenance
            a breeze. With minimal effort, you can maintain a polished and
            flawless exterior, saving time and ensuring your car always looks
            its best.
          </ListItem>
          <ListItem>
            <b>UV Protection:</b> Our ceramic coating includes UV inhibitors
            that shield your vehicle's paint from the damaging effects of the
            sun's rays. By blocking harmful UV radiation, it prevents paint
            fading and oxidation, preserving the vibrant color and luster of
            your car's exterior for years to come, even under intense sunlight.
          </ListItem>
          <ListItem>
            <b>Chemical Resistance:</b> The ceramic coating creates a protective
            barrier that repels chemical stains and etching caused by bird
            droppings, tree sap, and insect residue. With its exceptional
            chemical resistance, your car remains shielded from the corrosive
            effects of environmental contaminants, ensuring long-term protection
            and peace of mind.
          </ListItem>
        </UnorderedList>

        <Text>
          Protect and beautify your car with our professional ceramic coating
          services. At LA Mobile Car Detailing, we understand the importance of
          preserving the beauty and value of your vehicle. Our ceramic coating
          services provide unmatched protection against the elements while
          enhancing its aesthetic appeal, ensuring your car maintains its
          showroom shine for years to come. Discover more about our{" "}
          <Link href={"/services"} textDecoration={"underline"}>
            premium car care solutions
          </Link>{" "}
          where you can explore a comprehensive range of services tailored to
          meet your specific needs. From paint correction to interior detailing,
          we offer personalized solutions to elevate your driving experience and
          keep your car looking its best. Ready to experience the difference?
          Don't hesitate to{" "}
          <Link href={"/contact"} textDecoration={"underline"}>
            contact us
          </Link>{" "}
          for expert advice and scheduling. Our team of professionals is
          dedicated to providing top-notch service and exceeding your
          expectations at every turn. Elevate your car care routine with LA
          Mobile Car Detailing.
        </Text>
      </Stack>
    </Box>
  );
};
