import React from 'react';
import {SiteData} from "../Constants/siteData";
import {Heading, Link, Text} from "@chakra-ui/react";

const LatestNews = () => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="section_header text-left">
                <Heading as={'span'} textTransform={'uppercase'} fontSize={'1rem'}
                         color={'#FFA903'}>{SiteData.blog.h1}</Heading>
                <Heading as={'h2'} py={2}>{SiteData.blog.h2}</Heading>
                <Text fontSize={'1.2rem'}>{SiteData.blog.heading}</Text>
            </div>
            <div className="read_more read_all">
                <Link className="button" href={SiteData.blog.url} style={{fontWeight: 'bold'}}>Read our blog</Link>
            </div>
        </div>
    );
};

export default LatestNews;