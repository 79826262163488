import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList,} from "@chakra-ui/react";

export const LuxuryInteriorDetailing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={"h2"}>Luxury Interior Detailing in Los Angeles</Heading>
                <Text>
                    At LA Mobile Car Detailing, we specialize in luxury interior detailing, offering an unmatched level
                    of care and precision for your vehicle's interior. Serving the elite of Los Angeles, including areas
                    like Beverly Hills and Bel Air, we bring our services directly to you, ensuring convenience without
                    compromise. Our team understands that your vehicle is not just a mode of transportation, but a reflection
                    of your lifestyle and status. That's why we treat each car with the utmost respect and attention to detail,
                    ensuring that every aspect of your interior is impeccably cleaned and restored to its original glory.
                    Whether you're preparing for a special event or simply desire the best for your vehicle, LA Mobile Car
                    Detailing is here to exceed your expectations.
                </Text>
                <Text>
                    Our luxury interior detailing goes beyond the standard clean-up; it's a meticulous process that
                    rejuvenates and protects your vehicle's interior, enhancing its comfort and elegance. Using only the
                    finest eco-friendly products, we carefully treat every surface, from leather seats to intricate
                    dashboard details, ensuring every inch of your vehicle's interior is immaculate. Our experienced technicians
                    employ advanced techniques to remove even the toughest stains and odors, leaving your car smelling fresh
                    and looking flawless. With our attention to detail and commitment to excellence, you can trust LA Mobile Car
                    Detailing to deliver results that surpass your expectations. Experience the luxury of a professionally detailed
                    interior and elevate your driving experience with LA Mobile Car Detailing.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Comprehensive Interior Cleaning:</b> Our thorough cleaning process covers every aspect of
                        your car's interior, ensuring a spotless and hygienic cabin environment.
                    </ListItem>
                    <ListItem>
                        <b>High-End Materials and Techniques:</b> We use premium quality products and advanced
                        techniques to care for luxury materials, preserving the integrity and appearance of your
                        vehicle’s interior.
                    </ListItem>
                    <ListItem>
                        <b>Customized Approach:</b> Every vehicle is unique, and so are our detailing services. We
                        tailor our approach to suit the specific needs of your luxury car, delivering personalized care.
                    </ListItem>
                    <ListItem>
                        <b>Eco-Friendly Products:</b> Sustainability is at the heart of our services. Our eco-friendly
                        detailing solutions protect your vehicle and the environment.
                    </ListItem>
                    <ListItem>
                        <b>Interior Protection:</b> Beyond cleaning, we provide protective treatments to safeguard
                        your interior against wear and tear, UV damage, and spills, ensuring long-lasting beauty and
                        functionality.
                    </ListItem>
                    <ListItem>
                        <b>Attention to Detail:</b> From intricate stitching to delicate surfaces, our technicians pay
                        close attention to every detail, ensuring a flawless finish that exceeds your expectations.
                    </ListItem>
                    <ListItem>
                        <b>Convenient Mobile Service:</b> Our mobile detailing service brings luxury care directly to
                        your doorstep, saving you time and hassle while maintaining the highest standards of quality
                        and convenience.
                    </ListItem>
                </UnorderedList>

                <Text>
                    Enhance the luxury and comfort of your vehicle with our specialized interior detailing services. 
                    Our team of expert technicians is dedicated to elevating your driving experience by revitalizing your car's 
                    interior to its pristine condition. From deep cleaning to meticulous restoration, we take pride in delivering 
                    exceptional results that exceed your expectations.
                    For more information, visit our <Link href={"/services"} textDecoration={"underline"}>full range of services</Link> where you can explore our comprehensive offerings designed to meet your specific needs. Whether you require leather conditioning, stain removal, or odor elimination, our tailored services cater to every aspect of luxury car care.
                    Ready to experience the difference? Don't hesitate to <Link href={"/contact"} textDecoration={"underline"}>contact us</Link> to schedule an appointment with our experienced team. Discover the ultimate in interior detailing and embark on a journey of luxury and sophistication with us.
                </Text>

            </Stack>
        </Box>
    );
};
