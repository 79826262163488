import React, {lazy} from 'react';
import Banner from '../../Containers/Banner';
import Blog from '../../Containers/Blogs/Blog';
import FunFact01 from '../../Containers/Funfacts/FunFact01';
import Testimonial01 from '../../Containers/Testimonials/Testimonial01';
import Slider from '../../Containers/ThemeSliders/Slider';
import {SiteData} from "../../Constants/siteData";
import {SEO} from "../../Components/SEO/SEO";
import Map from "../../Components/ContactElements/Map";
import {Box} from "@chakra-ui/react";

const ExperiencesWide = lazy(() => import('../../Containers/ExperiencesWide/Experience'));
const AdditionalContent = lazy(() => import('../../Components/AdditionalContent/AdditionalContent'));
const ContactFormSection = lazy(() => import('../../Components/ContactFormSection/ContactFormSection'));
const HomeSection = lazy(() => import('../../Constants/homeSection'));

const Home01 = () => {
    return (
        <div className="main_wrapper">
            <SEO/>
            <Slider/>

            <ExperiencesWide/>
            <FunFact01/>
            <HomeSection/>
            <Testimonial01 pb='pd_btom_80'/>
            <Blog/>
            <Banner
                title={SiteData.contact.closingText}
                heading='Call Us Anytime'
                phone={SiteData.phoneNumber}
                bannerType='banner banner_bg_color'
            />
            <ContactFormSection data={[{
                id: '1',
                iconClass: 'ion-ios-telephone-outline',
                infoBody01: 'Call us Today!',
                infoBody02: SiteData.phoneNumber,
                link: SiteData.telLink,
                cta: 'Provide some information and get a FREE quote today',
                heading: 'Get a FREE estimate'
            }]}/>
            <AdditionalContent/>
            <Box pt={10} mb={{base: 10, md: 20}}>
                <div className="container">
                    <div className="gmapbox">
                        <div id="googleMap" className="map">
                            <Map height={'200'}/>
                        </div>
                    </div>
                </div>
            </Box>

        </div>
    );
};

export default Home01;