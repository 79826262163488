import React from 'react';
import {SiteData} from "../Constants/siteData";
import {Box, Heading} from "@chakra-ui/react";

const TopFeatures = () => {
    return (
        <Box>
            <div className="top_feature">
                <div className="container">
                    <Box display={'flex'} gap={55} alignItems={'center'} justifyContent={'center'}>
                        <img src="images/about/badge1.png" alt="top choice"/>
                        <img src="images/about/badge2.png" alt="top rated"/>
                    </Box>
                    <Box py={10}>
                        <Heading as={'h2'}>{SiteData.about.headingText.commitment}</Heading>
                    </Box>
                </div>
            </div>
        </Box>
    );
};

export default TopFeatures;