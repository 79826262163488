import React from "react";
import {posts} from "./posts";
import {Box, Button, Heading, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {ExteriorPolishingWaxing} from "../Components/ExteriorPolishingWaxing/ExteriorPolishingWaxing";
import {CeramicCoating} from "../Components/CeramicCoating/CeramicCoating";
import {LuxuryInteriorDetailing} from "../Components/LuxuryInteriorDetailing/LuxuryInteriorDetailing";
import {PetHairRemoval} from "../Components/PetHairRemoval/PetHairRemoval";
import LAMobileDetailingMeta from '../assets/images/meridenmeta.jpg'

const phoneNumber = "(323) 713-0719";

export const SiteData = {
    "hoursOfOperation": "24/7",
    "city": "Los Angeles, CA",
    phoneNumber,
    "title": "LA Mobile Car Detailing | Premium Auto Care in Los Angeles | 323-713-0719",
    "telLink": "tel:3237130719",
    "buttonCtaText": "Call Now for Luxury Car Detailing!",
    emailFooterText: `For all your Mobile Car Detailing needs, call Mobile Car Detailing Los Angeles at ${phoneNumber}`,
    "keywords": "Mobile Car Detailing, Los Angeles, Luxury, Beverly Hills, Eco-Friendly, 24/7 Service, High-End Detailing.",
    "footerText": "LA Mobile Car Detailing, Redefining Auto Luxury. All rights reserved.",
    "ogImage": LAMobileDetailingMeta,
    "navLinks": [
        {"title": "Home", "href": "/"},
        {"title": "Services", "href": "/services"},
        {"title": "About", "href": "/about"},
        {"title": "Contact Us", "href": "/contact"},
        {"title": "Blog", "href": "/blog"},
        {"title": "Careers", "href": "/careers", "hideFromTopNav": true}
    ],
    "homepage": {
        "url": "https://www.lamobilecardetailing.com",
        "bgImage": "luxuryCarDetailingBgImage",
        "metaData": {
            "title": "Luxury Mobile Car Detailing in Los Angeles | Eco-Friendly | Call 323-713-0719",
            "description": "Elevating Auto Care with Eco-Friendly, Custom Detailing Services"
        },
        "h1": "Premiere Mobile Car Detailing in Los Angeles",
        "subtitle": "Elevating Auto Care with Eco-Friendly, Custom Detailing Services",
        "servicesTitle": "Exclusive Detailing Services for the Discerning Clientele",
        "servicesHeader": "Premium Services Tailored for Your Luxury Vehicle",
        "titleHead": "DETAILING PERFECTION BY THE NUMBERS",
        "sectionTitle": "Every Detail Counts in Our Pursuit of Perfection",
        "content": [
            <>
                <Heading as="h2" size={'md'}>Hey LA, Let's Give Your Car Some Love with Los Angeles Mobile Car
                    Detailing!</Heading>
                <Text>Ever seen your car in that perfect LA sunshine and wished it could always look that shiny? That's
                    exactly what we aim for at Los Angeles Mobile Car Detailing. Imagine this: you're walking your dog
                    down
                    Venice Beach, and there it is – your car, shining like a diamond. That's our goal, to make your car
                    dazzle right where you are – and at a price that won't make your wallet cry.
                </Text>
                <Text>Do you remember the feeling of cruising down the 101 in a car that turns heads? We're here to
                    bring that
                    back. Whether jazzing up your everyday ride or adding extra flair to your weekend cruiser, we're on
                    it.
                    Our team is all about combining friendly smiles with pro-level car care. We don't just wash cars; we
                    give them a new lease on life.
                </Text>
                <Heading as="h2" size={'md'}>Tailor-Made Car Detailing for Your LA Hustle</Heading>
                <Text>Life in Los Angeles is like a fast-paced movie, and finding time for car detailing can feel like
                    an extra chore. But don't sweat it – we come to you. Just hit us up at <Link
                        href={"tel:323-713-0719"}>323-713-0719</Link>, and our crew will roll up ready to transform your
                    car. Your driveway, parking spot at work, you name it – we bring our best game anywhere in LA.
                </Text>
                <Heading as="h2" size={'md'}>Los Angeles Mobile Car Detailing: Your Ride's New Best
                    Friend</Heading>
                <Text>If your car is looking a little down, a quick call to us at <Link href={"tel:323-713-0719"}>(323)
                    713-0719</Link> is all it takes. Think of us as your car's pick-me-up, ready to bring back that
                    fresh-from-the-lot feel without any fuss. Our prices? As clear as the view from Griffith
                    Observatory. And we're all about payment flexibility – because life's complicated enough, right?
                </Text>
                <Heading as="h2" size={'md'}>Our Detailing Recipe for Car Perfection</Heading>
                <Text>Curious about what we whip up at Los Angeles Mobile Car Detailing? Here's a sneak peek:</Text>
                <UnorderedList mb={0}>
                    <ListItem>Glamorous Exteriors: We treat your car’s outside like it's ready for the red
                        carpet.</ListItem>
                    <ListItem>Spotless Interiors: Dive into a cabin that’s as fresh as the Malibu breeze.</ListItem>
                    <ListItem>Engine Pampering: It's not just about looking good, but feeling good under the
                        hood.</ListItem>
                    <ListItem>Headlight Facelift: Bright and clear for those nights cruising down Mulholland
                        Drive.</ListItem>
                    <ListItem>Sleek Paint Jobs: Scratches and marks? Consider them gone.</ListItem>
                    <ListItem>Scent Makeover: We make your car smell like it’s just off the lot.</ListItem>
                </UnorderedList>
                <Text>Every car gets its moment to shine because we believe in treating every ride as if it's our
                    own.</Text>
                <Heading as="h2" size={'md'}>Luxury Care at Friendly Prices with Los Angeles Mobile Car
                    Detailing</Heading>
                <Text>Top-tier car care shouldn’t mean emptying your pockets. We offer you the luxury of high-quality
                    detailing without the sky-high prices. No hidden costs, just honest, wallet-friendly service that’ll
                    make both you and your car happy.
                </Text>
                <Heading as="h2" size={'md'}>What Makes Us the Talk of the Town in LA?</Heading>
                <Text>
                    Why do Angelenos keep coming back to us? Here's the lowdown:
                </Text>
                <UnorderedList mb={0}>
                    <ListItem>Swift and detailed – because in LA, every minute counts.</ListItem>
                    <ListItem>From the sunroof to the tires – we lavish attention on every part of your car.</ListItem>
                    <ListItem>We travel to you – servicing any spot in LA, be it Beverly Hills or Silver
                        Lake.</ListItem>
                    <ListItem>Great prices, stellar service – it's just how we roll.</ListItem>
                    <ListItem>Car fanatics at heart – we handle every vehicle with care and passion.</ListItem>
                </UnorderedList>
                <Text>
                    We're not just a business; we're a part of LA's heartbeat, bringing a little extra shine to our
                    city, one car at a time.
                </Text>

                <Heading as="h2" size={'md'}>Get Your Car's Makeover Started Today!</Heading>
                <Text>
                    In LA and thinking about car detailing? We're just a call away. Dial <Link
                    href={"tel:323-713-0719"}>(323) 713-0719</Link> for Los Angeles Mobile Car Detailing. We're thrilled
                    to offer you a free quote and
                    show
                    you why we're the local favorite. All payment methods are welcome – let's add some sparkle to your
                    ride.
                    Thanks for considering us – we can't wait to make your car the best-looking one on the block.
                </Text>

                <Button as={'a'} href={'tel:3237130719'} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for Immediate
                    Assistance</Button>
            </>
        ],
        "values":
            [
                {"id": 0, "number": "1000+", "name": "Satisfied Clients", "icon": "FaSmile"},
                {"id": 1, "number": "24/7", "name": "Service Availability", "icon": "FaClock"},
                {"id": 2, "number": "100%", "name": "Eco-Friendly Practices", "icon": "FaLeaf"},
                {"id": 3, "number": "30min", "name": "Average Arrival Time", "icon": "FaCarSide"}
            ],
        "servicesSubtitle":
            () => (
                <Text color="muted" fontSize={{base: "lg", md: "xl"}}>
                    Explore the array of <Link href={"/services"} textDecoration={"underline"}>luxury detailing
                    services</Link> we offer at LA Mobile Car Detailing. Specializing in high-end vehicles, we provide
                    everything from eco-friendly deep cleaning to advanced ceramic coatings, ensuring your car not only
                    shines but is also protected. Serving affluent neighborhoods like Beverly Hills and Bel Air, we
                    bring
                    top-tier car care right to your doorstep.
                </Text>
            ),
        featuredServices: [
            {
                name: "Luxury Interior Detailing",
                description: "Expert interior detailing services, ensuring meticulous care for your luxury vehicle’s interior, using eco-friendly and top-quality products.",
                icon: "FaCar",
                path: "/luxury-interior-detailing"
            },
            {
                name: "Exterior Polishing & Waxing",
                description: "High-end exterior polishing and waxing services, bringing out the natural shine of your vehicle, while providing long-lasting protection.",
                icon: "FaBuffer",
                path: "/exterior-polishing-waxing"
            },
            {
                name: "Ceramic Coating",
                description: "State-of-the-art ceramic coating for superior paint protection, ensuring your vehicle stays pristine in the bustling streets of Los Angeles.",
                icon: "FaSprayCan",
                path: "/ceramic-coating"
            },
            {
                name: "Pet Hair Removal",
                description: "Expertly remove pet hair from your vehicle's interior, ensuring a clean and fresh cabin",
                icon: "FaHandSparkles",
                path: "/pet-hair-removal"
            },
        ],
        "services": [
            {
                id: "1",
                title: "Expert Detailing Team",
                text: () => (
                    <Text>
                        Our team, comprised of meticulously trained and highly experienced detailing professionals who specialize in enhancing luxury vehicles, ensures that your car receives the utmost care and attention to detail it deserves.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Premium Services at Competitive Rates",
                text: () => (
                    <Text>
                        We pride ourselves on providing premium detailing services at competitive rates. With a transparent pricing policy, we guarantee no hidden costs, delivering exceptional value and quality to our customers.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Convenient Mobile Detailing",
                text: () => (
                    <Text>
                        Experience the convenience of our mobile detailing service, where we bring unparalleled car care directly to your doorstep. Whether you're at home or work, our mobile service offers convenience without compromising on the quality of our workmanship.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Committed to Customer Satisfaction",
                text: () => (
                    <Text>
                        At LA Mobile Car Detailing, customer satisfaction reigns supreme. We are dedicated to not only meeting but surpassing your expectations with every service we provide. Your satisfaction is our driving force, and we strive to ensure that your experience with us is nothing short of exceptional.
                    </Text>
                ),
            },
        ],

        footerHeading: "Connect with LA Mobile Car Detailing",
        footerText: () => (
            <>
                For premier{" "}
                <a href="https://www.lamobilecardetailing.com/" target={"_blank"} rel={"noreferrer"}>
                    mobile car detailing
                </a>{" "}
                in Los Angeles, including Beverly Hills and Bel Air, LA Mobile Car Detailing is at your service. <Link
                href={"/contact"} textDecoration={"underline"}>Reach
                out to us anytime</Link> for bookings or inquiries. We are
                committed to delivering excellence in every detail, ensuring your vehicle reflects the luxury and
                sophistication it deserves.
            </>
        ),
        footerText2: "Elevating Your Vehicle's Aesthetics with Exceptional Care",
    },
    careers: {
        url: "https://www.lamobilecardetailing.com/careers/",
        metaData: {
            title: "Join Our Team - Career Opportunities at LA Mobile Car Detailing",
            description: "Seeking a career in luxury auto care? LA Mobile Car Detailing in Los Angeles offers exciting opportunities for dedicated professionals. Be part of a team that values excellence and innovation in car detailing."
        },
        h1: "We're Hiring - Embark on Your Career with LA Mobile Car Detailing",
        h2: [
            "Why Choose LA Mobile Car Detailing?",
            "Explore Our Current Openings",
            "Commitment to Professional Development and Growth"
        ],
        headingText: {
            whyJoin: "Join a leading team in luxury car detailing, where your skills will be nurtured and your professional growth fostered. At LA Mobile Car Detailing, we're more than just a company - we're a community passionate about cars.",
            openings: "View our latest job openings and find your place in our dynamic team. Whether you're experienced in car detailing or eager to learn, we offer various roles to suit your skills and aspirations.",
            commitment: "Our commitment to our team extends beyond the workplace. We invest in our employees' growth, offer competitive benefits, and foster a culture of respect and teamwork."
        },
        content: [
            <Text>
                Welcome to the Careers page at LA Mobile Car Detailing. Here, you have the opportunity to join a team
                that's reshaping the luxury car care industry in Los Angeles. Whether you're a seasoned professional or
                new to the field, we offer a dynamic environment where your talent in high-end auto detailing will
                shine.
            </Text>,
            <Text>
                Our team is the heartbeat of our service. We're looking for individuals who share our passion for
                excellence in car detailing, and who are eager to learn and grow with us. From skilled detailers to
                customer service experts, each role at LA Mobile Car Detailing is crucial in delivering the exceptional
                service our clients expect.
            </Text>,
            <Text>
                We believe in nurturing our employees' skills and ambitions. Through comprehensive training programs,
                competitive compensation, and a supportive work environment, we ensure that our team members are
                equipped to succeed and feel valued.
            </Text>,
            <Text>
                Being part of LA Mobile Car Detailing means more than just a job; it's about being part of a community
                that takes pride in delivering top-tier luxury car care. We are committed to maintaining a workplace
                where teamwork, respect, and integrity are at the forefront.
            </Text>,
            <Text>
                Ready to drive your career forward with LA Mobile Car Detailing? We're excited to welcome new members to
                our team who are ready to make a difference in the world of luxury auto care.
            </Text>
        ]
    },

    about: {
        url: "https://www.lamobilecardetailing.com/about/",
        metaData: {
            title: "About LA Mobile Car Detailing - Premier Luxury Auto Care in Los Angeles",
            description: "Discover LA Mobile Car Detailing, your elite partner for luxury car detailing in Los Angeles. Specializing in high-end vehicles, offering eco-friendly, bespoke services."
        },
        h1: "About LA Mobile Car Detailing",
        h2: [
            "Dedicated to Luxury Car Care",
            "Skilled Detailing Professionals",
            "Wide Range of Detailing Services"
        ],
        headingText: {
            commitment: "Committed to providing top-notch luxury car detailing, tailored to meet the unique needs of high-end vehicles in Los Angeles.",
            team: "Our team of detailing experts is well-versed in handling luxury cars, delivering unparalleled quality and attention to detail.",
            services: "Explore our extensive range of detailing services, from interior cleaning to ceramic coating, all designed to maintain and enhance the beauty of your luxury vehicle."
        },
        services: [
            {
                id: "1",
                title: "Luxury Interior Detailing",
                href: "/luxury-interior-detailing",
                icon: "FaCar",
                description: (
                    <Text>
                        Specializing in luxury vehicles, we meticulously clean and rejuvenate interiors, using premium
                        products that are safe for all materials. Our service ensures every detail, from leather seats
                        to delicate trim, is treated with utmost care, leaving your car’s interior looking and feeling
                        luxurious. Trust us to elevate your driving experience with our expert interior detailing
                        services tailored to meet the highest standards of luxury and sophistication.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Exterior Polishing & Waxing",
                href: "/exterior-polishing-waxing",
                icon: "FaBuffer",
                description: (
                    <Text>
                        Our exterior polishing and waxing services are tailored to protect and enhance your vehicle's
                        appearance. We use high-quality products that bring out the natural shine of your car,
                        protecting its paint and keeping it looking its best. With our meticulous attention to detail
                        and commitment to excellence, your vehicle will radiate beauty and elegance on the road.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Ceramic Coating",
                href: "/ceramic-coating",
                icon: "FaSprayCan",
                description: (
                    <Text>
                        Our ceramic coating service provides a durable, protective layer for your vehicle’s paint,
                        offering enhanced gloss and superior protection against environmental elements. This advanced
                        treatment extends the life of the paint, ensuring your vehicle remains a standout on the streets
                        of Los Angeles. Experience the ultimate in paint protection and aesthetics with our premium
                        ceramic coating solutions.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Pet Hair Removal",
                href: "/pet-hair-removal",
                icon: "FaPaw",
                description: (
                    <Text>
                        Our pet hair removal service effectively cleans your vehicle’s interior, removing even the most
                        stubborn pet hair. We use specialized tools and techniques to ensure a thorough clean, leaving
                        your car’s interior pristine and comfortable for both you and your pets. Say goodbye to
                        unsightly pet hair and hello to a fresh, pet-friendly interior with our professional pet hair
                        removal services.
                    </Text>
                ),
            }
        ]
    },
    services: {
        url: "https://www.lamobilecardetailing.com/services/",
        metaData: {
            title: "LA Mobile Car Detailing: Premier Auto Care Services in Los Angeles",
            description: "Explore our superior detailing services in Los Angeles. From luxury interior detailing to advanced ceramic coatings, LA Mobile Car Detailing provides exceptional care for your vehicle."
        },
        h1: "Detailing Services in Los Angeles, CA",
        h2: "Elevate Your Vehicle’s Aesthetics with Our Expertise",
        content: (
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">
                        LA Mobile Car Detailing: Your Elite Car Care Partner in Los Angeles
                    </Heading>
                    <Text>
                        At LA Mobile Car Detailing, we pride ourselves on providing top-tier detailing services right to
                        your doorstep. Specializing in luxury vehicles, our expert team offers a comprehensive range of
                        detailing options, tailored to maintain and enhance the beauty and integrity of your car.
                    </Text>

                    <Heading as="h3" size="lg">
                        Why Choose LA Mobile Car Detailing
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b>Expertise in Luxury Vehicles:</b> Our professional team is highly trained in handling
                                luxury vehicles, ensuring a bespoke detailing experience.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Eco-Friendly Detailing Solutions:</b> We use only the finest, eco-friendly products,
                                maintaining your car’s splendor while protecting the environment.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b>Convenience Meets Quality:</b> Enjoy the convenience of our mobile service with the
                                assurance of exceptional quality, right in Los Angeles.
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Our Range of Detailing Services
                    </Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text>
                                <b><Link href={"/services/luxury-interior-detailing"}>Luxury Interior Detailing:</Link></b> Tailored
                                cleaning and treatment for your vehicle's interior, ensuring a pristine and elegant
                                finish.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b><Link href={"/services/exterior-polishing-waxing"}>Exterior Polishing &
                                    Waxing:</Link></b> Expert exterior care including polishing and waxing, enhancing
                                your vehicle's allure and protecting its surface.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b><Link href={"/services/ceramic-coating"}>Ceramic Coating
                                    Application:</Link></b> Advanced coating services for superior paint protection,
                                offering a lasting shine and enhanced durability.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <b><Link href={"/services/pet-hair-removal"}>Pet Hair Removal:</Link></b> Expertly
                                remove pet hair from your vehicle's interior, ensuring a clean and fresh cabin
                            </Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">
                        Personalized Care for Your Vehicle
                    </Heading>
                    <Text>
                        We understand that each vehicle has its unique needs. Our personalized approach ensures that
                        your car receives the individual attention it deserves, enhancing its beauty and longevity.
                    </Text>

                    <Heading as="h3" size="lg">
                        Safety and Satisfaction: Our Top Priorities
                    </Heading>
                    <Text>
                        Your vehicle's safety and your satisfaction are our top priorities. We are committed to
                        delivering a service that not only meets but exceeds your expectations, making LA Mobile Car
                        Detailing your trusted car care partner.
                    </Text>

                    <Heading as="h3" size="lg">
                        Get in Touch With Us
                    </Heading>
                    <Text>
                        For exceptional mobile car detailing in Los Angeles, turn to LA Mobile Car Detailing. Contact us
                        today for premium service, tailored specifically to your luxury vehicle. We are here to enhance
                        your car care experience.
                    </Text>
                </Stack>
            </Box>
        ),
        services: [
            {
                id: "1",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/luxury-interior-detailing"}>Luxury Interior Detailing</Link>
                        </b> - Transform your vehicle's interior with our meticulous detailing service, ensuring a
                        pristine and luxurious cabin environment.
                    </Text>
                ),
            },
            {
                id: "2",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/exterior-polishing-waxing"}>Exterior Polishing & Waxing</Link>
                        </b> - Our expert polishing and waxing services bring out the natural elegance and shine of your
                        vehicle's exterior.
                    </Text>
                ),
            },
            {
                id: "3",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/ceramic-coating"}>Ceramic Coating</Link>
                        </b> - Protect your paint with our advanced ceramic coating service, offering long-lasting shine
                        and protection.
                    </Text>
                ),
            },
            {
                id: "4",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/engine-bay-cleaning"}>Engine Bay Cleaning</Link>
                        </b> - Our detailed engine bay cleaning enhances performance and maintains the pristine
                        condition of your engine.
                    </Text>
                ),
            },
            {
                id: "5",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/headlight-restoration"}>Headlight Restoration</Link>
                        </b> - Improve visibility and aesthetics with our professional headlight restoration service.
                    </Text>
                ),
            },
            {
                id: "6",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/event-detailing"}>Mobile Detailing for Events</Link>
                        </b> - Ensure your vehicle stands out at special events with our on-site detailing services.
                    </Text>
                ),
            },
            {
                id: "7",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/custom-packages"}>Custom Detailing Packages</Link>
                        </b> - Tailor-made detailing packages designed for the specific needs of your luxury vehicle.
                    </Text>
                ),
            },
            {
                id: "8",
                text: () => (
                    <Text>
                        <b>
                            <Link href={"/services/pet-hair-removal"}>Pet Hair Removal</Link>
                        </b> - Expertly remove pet hair from your vehicle's interior, ensuring a clean and fresh cabin
                        space.
                    </Text>
                ),
            }
        ],
    },
    contact: {
        url: "https://www.lamobilecardetailing.com/contact/",
        metaData: {
            title:
                "Connect with LA Mobile Car Detailing: Excellence in Every Detail | Call 323-713-0719",
            description:
                "Seeking top-notch car detailing in Los Angeles? Contact LA Mobile Car Detailing for outstanding service and meticulous attention to detail, available at your convenience.",
        },
        h1: "Reach Out to LA Mobile Car Detailing",
        subtitle: () => (
            <>
                Trust is essential when it comes to car care. At LA Mobile Car Detailing, we cherish your confidence in
                us. Choosing us means selecting supreme care and dedication for your vehicle. Need our services? We're
                just a call away at <Link href={"tel:323-713-0719"}>323-713-0719</Link>.
            </>
        ),
        sectionHeading: "Why Opt for LA Mobile Car Detailing?",
        signUpDescription:
            "For online queries or appointment bookings, please use our contact form below. We assure a prompt and helpful response.",
        feedbackText:
            "Your feedback is our roadmap to excellence! Let us know about your experience or if you have any concerns. At LA Mobile Car Detailing, your complete satisfaction is our ultimate goal.",
        closingText:
            "Ready for a car detailing transformation? We're here to make it happen.",
        valueProps: [
            {
                title: "Convenience Meets Quality:",
                description:
                    "We bring exceptional car detailing to your doorstep in Los Angeles. Our mobile service ensures convenience without compromising on quality.",
            },
            {
                title: "Comprehensive Detailing Solutions:",
                description:
                    "From thorough interior cleaning to meticulous exterior polishing, LA Mobile Car Detailing offers a complete range of services for your car's needs.",
            },
            {
                title: "Expert Care with Premium Products:",
                description:
                    "Our team uses only the best products and techniques, ensuring that your vehicle receives the highest standard of care.",
            },
            {
                title: "Customized Services:",
                description:
                    "Every car is unique, and so are our services. We offer personalized detailing solutions to match the specific needs of your vehicle.",
            },
            {
                title: "Dedication to Customer Satisfaction:",
                description:
                    "At LA Mobile Car Detailing, we're committed to exceeding your expectations with every service. Your satisfaction is the measure of our success.",
            },
        ],
    },
    blog: {
        url: "https://www.lamobilecardetailing.com/blog",
        metaData: {
            title: "LA Mobile Car Detailing Blog: Expert Tips & Car Care Insights",
            description: "Explore the latest in luxury car care with LA Mobile Car Detailing's blog. Discover expert tips, trends, and advice on maintaining the elegance of your vehicle in Los Angeles.",
        },
        h1: "Expert Car Detailing Insights & Tips",
        h2: "Luxury Car Care Chronicles from Los Angeles",
        heading: "At LA Mobile Car Detailing, we're dedicated to providing top-tier luxury car care insights. Our blog is your resource for everything from detailing tips to the latest trends in auto care. Stay tuned for updates and feel free to reach out for personalized car care advice.",
        posts: posts,
    },
    luxuryInteriorDetailing: {
        url: "https://www.lamobilecardetailing.com/luxury-interior-detailing/",
        metaData: {
            title: "Luxury Interior Car Detailing in Los Angeles | LA Mobile Car Detailing",
            description: "Elevate your vehicle's interior with our luxury detailing services in Los Angeles. Contact LA Mobile Car Detailing for unparalleled interior care."
        },
        h1: "Luxury Interior Detailing in Los Angeles",
        subtitle: "Experience the ultimate in interior car care with our luxury detailing services, designed for the discerning vehicle owner in Los Angeles.",
        body: <LuxuryInteriorDetailing/>,
    },
    exteriorPolishingWaxing: {
        url: "https://www.lamobilecardetailing.com/exterior-polishing-waxing/",
        metaData: {
            title: "Exterior Polishing & Waxing Services in Los Angeles | LA Mobile Car Detailing",
            description: "Bring back the shine to your vehicle with LA Mobile Car Detailing's exterior polishing and waxing services in Los Angeles."
        },
        h1: "Professional Exterior Polishing & Waxing in Los Angeles",
        subtitle: "Revitalize your vehicle’s appearance with our professional polishing and waxing services, offering the best care for your car’s exterior.",
        body: <ExteriorPolishingWaxing/>,
    },
    ceramicCoating: {
        url: "https://www.lamobilecardetailing.com/ceramic-coating/",
        metaData: {
            title: "Ceramic Coating in Los Angeles | LA Mobile Car Detailing",
            description: "Protect and enhance your vehicle's paint with LA Mobile Car Detailing's ceramic coating services in Los Angeles."
        },
        h1: "Best Ceramic Coating Services in Los Angeles",
        subtitle: "Guard your vehicle’s paint against the elements with our advanced ceramic coating services, ensuring lasting shine and protection.",
        body: <CeramicCoating/>,
    },
    petHairRemoval: {
        url: "https://www.lamobilecardetailing.com/pet-hair-removal/",
        metaData: {
            title: "Pet Hair Removal Services in Los Angeles | LA Mobile Car Detailing",
            description: "Efficiently remove pet hair from your vehicle's interior with LA Mobile Car Detailing's specialized services in Los Angeles."
        },
        h1: "Expert Pet Hair Removal in Los Angeles",
        subtitle: "Specialized pet hair removal services to keep your vehicle’s interior immaculate and comfortable.",
        body: <PetHairRemoval/>,
    },
    testimonials: {
        h1: "What Our Clients Say",
        heading: "Experience the excellence of LA Mobile Car Detailing. Discover why our clients in Los Angeles love our luxury car care services.",
        testimonials: [
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a042581f4e34027805e",
                name: "Emily R.",
                logo: undefined,
                title: "Beverly Hills, CA",
                quote: '"LA Mobile Car Detailing transformed my car’s interior to look brand new. Their attention to detail impressed me. The best detailing service in Los Angeles!"',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=a0425e4f6",
                name: "Michael B.",
                logo: undefined,
                title: "Malibu, CA",
                quote: '"Exceptional service! The exterior polishing and waxing made my car shine like never before. LA Mobile Car Detailing is my top choice for car care in Los Angeles."',
            },
            {
                avatarUrl: "https://i.pravatar.cc/150?u=b0537f4d3",
                name: "Sophia T.",
                logo: undefined,
                title: "Bel Air, CA",
                quote: "\"I’m amazed by the quality of their ceramic coating service. LA Mobile Car Detailing provides not just cleaning but true car care. They’re the experts in luxury detailing in LA.\""
            }
        ],
    },
    404: {
        metaData: {
            title: "Oops! Page Not Found - LA Mobile Car Detailing | Luxury Car Care",
            description: "The page you’re looking for is not available. But worry not, LA Mobile Car Detailing is here for all your luxury car care needs in Los Angeles. Contact us for premium detailing services.",
            siteName: "LA Mobile Car Detailing | Premier Auto Care in Los Angeles",
        },
        h1: "Oops! 404 - It seems this page is not as spotless as your car.",
        subtitle: "We're sorry, but the page you’re searching for cannot be found.",
        p: "Looking for top-notch car detailing services in Los Angeles? LA Mobile Car Detailing is at your service. Explore our site for luxury car care options."
    }
};
