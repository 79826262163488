import React from 'react';
import FunBox01 from '../../Components/FunFactElements/FunBox01';
import {SiteData} from "../../Constants/siteData";


const FunFact01 = () => {
    return (
        <div id="funfacts" className="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="section_header">
                            <span className="section_sub_title">{SiteData.homepage.titleHead}</span>
                            <h2 className="section_title">{SiteData.homepage.sectionTitle}</h2>
                        </div>
                        <div className="fun_bottom">
                            <div className="row">
                                {
                                    SiteData.homepage.values.map(data =>
                                        <FunBox01
                                            key={data.id}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunFact01;