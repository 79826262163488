import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import LuxuryInteriorDetailing from "../Pages/Services/LuxuryInteriorDetailing";
import PetHairRemoval from "../Pages/Services/PetHairRemoval";
import CeramicCoating from "../Pages/Services/CeramicCoating";
import ExteriorPolishingWaxing from "../Pages/Services/ExteriorPolishingWaxing";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/luxury-interior-detailing',
                element: <LuxuryInteriorDetailing/>
            },
            {
                path: '/ceramic-coating',
                element: <CeramicCoating/>
            },
            {
                path: '/exterior-polishing-waxing',
                element: <ExteriorPolishingWaxing/>
            },
            {
                path: '/pet-hair-removal',
                element: <PetHairRemoval/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

