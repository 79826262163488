import React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const PetHairRemoval = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Pet Hair Removal Services in Los Angeles</Heading>
        <Text>
          LA Mobile Car Detailing offers specialized pet hair removal services,
          perfect for pet owners in Los Angeles who cherish both their furry
          friends and their vehicles. We understand the challenge of keeping
          your car's interior free from pet hair, and our service is designed to
          thoroughly remove even the most stubborn pet hair from your vehicle's
          upholstery and carpet. Our skilled technicians employ gentle yet
          effective methods to extract pet hair, ensuring a meticulous cleaning
          process that leaves your car fresh and pet-hair-free.
        </Text>
        <Text>
          Our team uses safe and effective techniques combined with specialized
          tools to ensure that your vehicle's interior is not only clean but
          also maintains its integrity and comfort. We take care to preserve the
          quality of your vehicle's interior fabrics and surfaces while removing
          all traces of pet hair. With our attention to detail and commitment to
          customer satisfaction, you can trust LA Mobile Car Detailing to
          provide exceptional pet hair removal services that exceed your
          expectations.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Thorough Cleaning:</b> We meticulously clean every corner of your
            vehicle's interior, ensuring no pet hair is left behind. Our skilled
            technicians utilize specialized tools and methods to reach even the
            most inaccessible areas, guaranteeing a comprehensive cleaning
            process that restores your car's interior to its pristine condition.
          </ListItem>
          <ListItem>
            <b>Gentle on Fabrics:</b> Our techniques are safe for all types of
            interior fabrics and surfaces. We understand the importance of
            preserving the integrity of your car's upholstery and interior
            materials, which is why we use gentle yet effective cleaning
            solutions that remove pet hair without causing any damage or
            discoloration.
          </ListItem>
          <ListItem>
            <b>Healthier Cabin Environment:</b> Removing pet hair also helps to
            improve the air quality inside your vehicle. By eliminating pet
            dander and other allergens, our pet hair removal services create a
            healthier and more comfortable cabin environment for you and your
            passengers to enjoy, promoting a cleaner and fresher driving
            experience.
          </ListItem>
          <ListItem>
            <b>Customized Approach:</b> We tailor our pet hair removal services
            to suit the specific needs and conditions of your vehicle. Whether
            your car requires a quick touch-up or a more intensive cleaning
            treatment, our experienced team customizes our approach to address
            your unique requirements, ensuring optimal results and customer
            satisfaction every time.
          </ListItem>
          <ListItem>
            <b>Professional Expertise:</b> Our team of trained professionals is
            dedicated to delivering exceptional pet hair removal services with
            precision and care. With years of experience in the automotive
            detailing industry, we have the expertise and knowledge to
            effectively remove pet hair from vehicles of all makes and models,
            providing you with outstanding results that exceed your
            expectations.
          </ListItem>
        </UnorderedList>
        <Text>
          Restore the pristine condition of your vehicle's interior with our
          effective pet hair removal service. For more details on how we can
          help you, explore our{" "}
          <Link href={"/services"} textDecoration={"underline"}>
            other detailing services
          </Link>{" "}
          or{" "}
          <Link href={"/contact"} textDecoration={"underline"}>
            reach out to us
          </Link>{" "}
          for a personalized consultation. Whether you need a comprehensive
          detailing package or specific cleaning solutions, LA Mobile Car
          Detailing is dedicated to exceeding your expectations and restoring
          the beauty of your vehicle inside and out.
        </Text>
      </Stack>
    </Box>
  );
};
