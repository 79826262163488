import React from 'react';
import {AspectRatio, Box, Heading, Image, Link, Tag, Text, VStack} from "@chakra-ui/react";
import {formatDate, getBlogImage} from "../../Utils/metaHelpers";

const BlogCards01 = ({data}) => {
    const {title, excerpt, slug, id, image, lastModified} = data;
    return (
        <Box className='col-lg-4 col-md-6' mb={{base: 5, md: 20}}>
            <article>
                <div className="post_img">
                    <Link href={`/blog/${slug}`}>
                        <AspectRatio maxH='250px' ratio={4 / 3}>
                            <Image src={image || getBlogImage(id)} alt="img"/>
                        </AspectRatio>
                    </Link>
                </div>
                <div className="post_content">
                    <div className="post_header">
                        <VStack py={4} spacing={2} align={'left'}>
                            <Box>
                                <Tag fontWeight={'bold'} variant={'ghost'} px={0}>{formatDate(lastModified)}</Tag>
                            </Box>
                            <Heading as={'h3'} className="post_title" fontSize={'1.5rem'}>
                                <Link href={`/blog/${slug}`}>{title}</Link>
                            </Heading>
                        </VStack>
                    </div>
                    <div className="post_intro">
                        <Text
                            color={'muted'}>{excerpt.split(/\s+/).length > 35 ? excerpt.split(/\s+/).slice(0, 35).join(' ') + '...' : excerpt}</Text>
                    </div>
                    <Box mt={5}>
                        <div className="post_footer">
                            <div className="read_more">
                                <Link href={`/blog/${slug}`}
                                      fontWeight={'bold'}><span>Read Article</span></Link>
                            </div>
                        </div>
                    </Box>
                </div>
            </article>
        </Box>
    );
};
export default BlogCards01;