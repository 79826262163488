import React from "react";
import {
  Box,
  Heading,
  ListItem,
  Link,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const ExteriorPolishingWaxing = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Exterior Polishing & Waxing in Los Angeles</Heading>
        <Text>
          LA Mobile Car Detailing brings you superior exterior polishing and
          waxing services right in Los Angeles. Our approach is designed to not
          only clean but also protect and enhance your vehicle’s exterior,
          catering especially to high-end cars in neighborhoods like Malibu and
          Beverly Hills. We understand that your vehicle's exterior is the first
          thing people notice, and it deserves nothing but the best care to
          maintain its pristine appearance.
        </Text>
        <Text>
          Using advanced techniques and high-quality products, we ensure your
          vehicle shines brilliantly, reflecting its true elegance. Our
          polishing and waxing services not only improve the aesthetic appeal
          but also provide a protective layer against environmental elements.
          From removing swirl marks to restoring faded paint, our skilled
          technicians meticulously work to bring out the beauty of your car's
          exterior, ensuring it stands out on the road and maintains its value
          over time.
        </Text>

        <UnorderedList spacing={3}>
          <ListItem>
            <b>Gentle yet Effective Polishing:</b> Our skilled technicians
            employ gentle yet effective polishing techniques to remove
            imperfections and bring out a natural shine without harming your
            car’s paint. We understand the importance of preserving the
            integrity of your vehicle's paintwork while achieving optimal
            results in enhancing its appearance.
          </ListItem>
          <ListItem>
            <b>Long-Lasting Protection:</b> Our waxing service goes beyond
            aesthetics; it provides a protective coating that safeguards your
            vehicle against scratches, UV rays, and other environmental factors.
            With our meticulous application process, your car's exterior
            receives long-lasting protection, ensuring its beauty endures
            through various driving conditions.
          </ListItem>
          <ListItem>
            <b>Attention to Detail:</b> At LA Mobile Car Detailing, we take
            immense pride in our attention to detail. Every inch of your
            vehicle’s exterior is meticulously cared for, from intricate
            crevices to prominent panels. Our technicians meticulously inspect
            and address any imperfections, ensuring a flawless finish that
            exceeds your expectations.
          </ListItem>
          <ListItem>
            <b>Customized Solutions:</b> We recognize that every vehicle is
            unique, and so are its detailing needs. Our services are tailored to
            match the specific requirements of your car, ensuring that our
            techniques are customized to address any unique challenges or
            characteristics. From luxury sedans to high-performance sports cars,
            we deliver customized solutions that prioritize the individuality of
            your vehicle.
          </ListItem>
          <ListItem>
            <b>Enhanced Gloss and Shine:</b> Our polishing and waxing services
            are designed to not only protect your vehicle but also enhance its
            gloss and shine. By using premium products and expert techniques, we
            ensure that your car stands out with a showroom-worthy finish that
            catches the eye and turns heads wherever you go.
          </ListItem>
          <ListItem>
            <b>Professional Grade Equipment:</b> We invest in state-of-the-art
            equipment and tools to deliver superior results for your vehicle's
            exterior. From buffing machines to precision applicators, our
            professional-grade equipment ensures that every aspect of the
            detailing process is executed with precision and efficiency,
            guaranteeing exceptional outcomes for your car.
          </ListItem>
          <ListItem>
            <b>UV Protection:</b> Our specialized waxing formulas include UV
            protection properties that shield your car's exterior from the
            damaging effects of the sun. With our UV-resistant coatings, your
            vehicle's paintwork remains vibrant and resistant to fading,
            preserving its aesthetic appeal for years to come.
          </ListItem>
          <ListItem>
            <b>Water-Repellent Coating:</b> Our waxing services incorporate
            water-repellent coatings that bead water off the surface, making it
            easier to maintain and ensuring a cleaner appearance between washes.
            This hydrophobic barrier not only enhances the visual appeal of your
            car but also simplifies maintenance, saving you time and effort.
          </ListItem>
        </UnorderedList>

        <Text>
          Elevate your vehicle’s appearance with our exterior polishing and
          waxing services. Our expert technicians are dedicated to bringing out
          the best in your car's exterior, ensuring it shines with unmatched
          brilliance and sophistication. Explore our{" "}
          <Link href={"/services"} textDecoration={"underline"}>
            other detailing options
          </Link>{" "}
          to discover a comprehensive range of services tailored to meet your
          specific needs. Whether you're looking for interior detailing, paint
          correction, or ceramic coating, we have the expertise and resources to
          exceed your expectations. Ready to experience the difference?{" "}
          <Link href={"/contact"} textDecoration={"underline"}>
            Get in touch
          </Link>{" "}
          with our team to discuss bespoke service packages designed to enhance
          and protect your vehicle's aesthetic appeal. Elevate your driving
          experience with LA Mobile Car Detailing.
        </Text>
      </Stack>
    </Box>
  );
};
