import React from 'react';
import Testimonial01 from '../Testimonials/Testimonial01';
import {SiteData} from "../../Constants/siteData";
import {Flex, Link, ListItem, UnorderedList} from "@chakra-ui/react";
import ContactFormSection from "../../Components/ContactFormSection/ContactFormSection";
import {useLocation} from "react-router-dom";

const ServicePageTemplate = ({body}) => {
    const {pathname} = useLocation();
    return (
      <div className="main_wrapper">
          <div className="container">
              <div className="project_details section">
                  <div className="row">
                      <div className="col-lg-8 col-md-12">
                          <div className="project_details_inner">
                              {!pathname.includes('/services') &&
                              <div className="post_img">
                                  <img src="images/services/details.png" alt="blog"/>
                              </div>
                              }
                              <div className="post_content">
                                  <div className="fulltext">
                                      {body}
                                  </div>
                              </div>
                          </div>

                      </div>

                      <div className="col-lg-4 col-md-12">
                          <div className="sidebar">
                              <div id="widgetnav" className="widget widget_menu">
                                  <div className="sidenav">
                                      <UnorderedList className="side_menu">
                                          {SiteData.about.services.map(({title, href, icon: Icon}) => {
                                              return (
                                                <ListItem>
                                                    <Link href={href}>
                                                        <Flex gap={2}>
                                                            <Icon fontSize={'1.5rem'}/>
                                                            {title}
                                                        </Flex>
                                                    </Link>
                                                </ListItem>
                                              )
                                          })}
                                      </UnorderedList>
                                  </div>
                              </div>

                              <div id="custom_2" className="widget widget_side_contact">
                                  <div className="side_contact">
                                      <Link href={SiteData.telLink} _hover={{textDecoration: 'none'}}>
                                          <div className="side_contact_top">
                                              <div className="side_contact_inner">
                                                  <p>Need a Tow?</p>
                                                  <span>Call Us Today</span>
                                              </div>
                                          </div>
                                      </Link>
                                      <Link href={SiteData.telLink} _hover={{textDecoration: 'none'}}>
                                          <div className="side_phone_inner">
                                              <div>{SiteData.phoneNumber}</div>
                                          </div>
                                      </Link>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Testimonial01 pb='pd_btom_80 pd_top_80'/>
          <ContactFormSection data={[{
              id: '1',
              iconClass: 'ion-ios-telephone-outline',
              infoBody01: 'Call us Today!',
              infoBody02: SiteData.phoneNumber,
              link: SiteData.telLink,
              heading: 'Get a FREE estimate',
              cta: 'Provide some information and get a FREE quote today'
          }]}/>
      </div>
    );
};

export default ServicePageTemplate;