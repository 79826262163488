export const posts = [
    {
        id: "0",
        title: "The Art of Luxury Car Detailing in Los Angeles",
        excerpt: "Dive into the world of luxury car detailing and discover how LA Mobile Car Detailing transforms high-end vehicles in Los Angeles.",
        slug: "luxury-car-detailing-los-angeles",
        tags: ["luxury car detailing", "Los Angeles", "auto care"],
        metaDescription: "Learn about the meticulous process and benefits of luxury car detailing offered by LA Mobile Car Detailing in Los Angeles.",
        lastModified: new Date('2023-12-15').toISOString()
    },

    {
        id: "1",
        title: "Eco-Friendly Detailing: Sustainable Practices in Auto Care",
        excerpt: "Explore how LA Mobile Car Detailing incorporates eco-friendly practices into luxury car care in Los Angeles.",
        slug: "eco-friendly-detailing-auto-care",
        tags: ["eco-friendly detailing", "sustainable", "luxury cars"],
        metaDescription: "Discover the importance and impact of eco-friendly detailing practices at LA Mobile Car Detailing.",
        lastModified: new Date('2023-12-15').toISOString()
    },
    {
        id: "2",
        title: "Mastering Ceramic Coatings for Ultimate Vehicle Protection",
        excerpt: "Uncover the secrets of ceramic coatings and how they offer unparalleled protection for luxury vehicles at LA Mobile Car Detailing.",
        slug: "ceramic-coatings-vehicle-protection",
        tags: ["ceramic coatings", "vehicle protection", "luxury cars"],
        metaDescription: "Find out how ceramic coatings provide long-lasting protection and shine for high-end vehicles at LA Mobile Car Detailing.",
        lastModified: new Date('2023-12-20').toISOString()
    },
    {
        id: "3",
        title: "Tips for Maintaining Your Luxury Car's Interior",
        excerpt: "Get expert advice on maintaining the pristine condition of your luxury car’s interior from LA Mobile Car Detailing.",
        slug: "maintaining-luxury-car-interior",
        tags: ["luxury car care", "interior maintenance", "detailing tips"],
        metaDescription: "Learn valuable tips and techniques for keeping your luxury car’s interior in top condition from LA Mobile Car Detailing.",
        lastModified: new Date('2024-1-04').toISOString()
    }
];
